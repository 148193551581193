// File: src/pages/Dashboard.tsx

import React, { useState, useEffect, useMemo } from 'react';
import OrderSummary from '../components/Orders/OrderSummary';
import OrderList from '../components/Orders/OrderList';
import { fetchOrders } from '../api/googleSheets';
import './Dashboard.css';
import { useAuth0 } from '@auth0/auth0-react';

const Dashboard: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [orders, setOrders] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filter, setFilter] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const loadOrders = async () => {
      try {
        setLoading(true);
        const accessToken = await getAccessTokenSilently();
        const data = await fetchOrders(accessToken);
        setOrders(data);
      } catch (err: unknown) {
        if (err instanceof Error) {
          setError(`Failed to load orders: ${err.message}`);
          console.error('Error loading orders:', err);
        } else {
          setError('Failed to load orders: An unknown error occurred');
          console.error('Unknown error loading orders:', err);
        }
      } finally {
        setLoading(false);
      }
    };

    loadOrders();
  }, [getAccessTokenSilently]);

  // Helper function to parse dates
  const parseDate = (dateStr: string) => {
    const [day, month, year] = dateStr.split('/');
    return new Date(`${year}-${month}-${day}`);
  };

  const validOrders = useMemo(
    () =>
      orders
        .filter(order => /^\d+$/.test(order.orderId))
        .map(order => {
          // Parse date
          const dateObject = parseDate(order.date);
          // Parse price
          const priceNumber = parseFloat(order.price) || 0;

          return {
            ...order,
            dateObject,
            priceNumber,
          };
        }),
    [orders]
  );

  const statusCounts = useMemo(() => {
    return validOrders.reduce((acc, order) => {
      acc[order.status] = (acc[order.status] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);
  }, [validOrders]);

  const filteredOrders = useMemo(() => {
    return validOrders.filter(order => {
      const matchesStatus = filter === 'All' || order.status === filter;
      const matchesSearch =
        searchTerm === '' ||
        order.orderId.toLowerCase().includes(searchTerm.toLowerCase()) ||
        order.customer.toLowerCase().includes(searchTerm.toLowerCase());
      return matchesStatus && matchesSearch;
    });
  }, [validOrders, filter, searchTerm]);

  const orderedItemsCount = useMemo(
    () =>
      filteredOrders.reduce(
        (sum, order) => sum + (parseInt(order.items) || 0),
        0
      ),
    [filteredOrders]
  );

  const printedFramesCounts = useMemo(() => {
    const whiteFrames = ['אותנטי', 'נקי'];
    const blackFrames = ['נועז', 'קלאסי'];

    return validOrders.reduce(
      (acc, order) => {
        if (order.status === 'הודפס') {
          if (whiteFrames.includes(order.frame)) {
            acc.white += parseInt(order.items) || 0;
          } else if (blackFrames.includes(order.frame)) {
            acc.black += parseInt(order.items) || 0;
          }
        }
        return acc;
      },
      { white: 0, black: 0 }
    );
  }, [validOrders]);

  // Helper function to check if two dates are the same day
  const isSameDay = (date1: Date, date2: Date) =>
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();

  // Sales Today and Yesterday
  const todaySales = useMemo(() => {
    const today = new Date();
    return validOrders.reduce((sum, order) => {
      if (isSameDay(order.dateObject, today)) {
        return sum + order.priceNumber;
      }
      return sum;
    }, 0);
  }, [validOrders]);

  const yesterdaySales = useMemo(() => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return validOrders.reduce((sum, order) => {
      if (isSameDay(order.dateObject, yesterday)) {
        return sum + order.priceNumber;
      }
      return sum;
    }, 0);
  }, [validOrders]);

  // Sales This Week and Last Week
  const salesLast7Days = useMemo(() => {
    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 6);
    sevenDaysAgo.setHours(0, 0, 0, 0);

    return validOrders.reduce((sum, order) => {
      if (order.dateObject >= sevenDaysAgo && order.dateObject <= today) {
        return sum + order.priceNumber;
      }
      return sum;
    }, 0);
  }, [validOrders]);

  const salesPrior7Days = useMemo(() => {
    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 7);
    sevenDaysAgo.setHours(0, 0, 0, 0);
    const fourteenDaysAgo = new Date();
    fourteenDaysAgo.setDate(today.getDate() - 13);
    fourteenDaysAgo.setHours(0, 0, 0, 0);

    return validOrders.reduce((sum, order) => {
      if (order.dateObject >= fourteenDaysAgo && order.dateObject < sevenDaysAgo) {
        return sum + order.priceNumber;
      }
      return sum;
    }, 0);
  }, [validOrders]);

  // Sales This Month and Same Period Last Month
  const salesThisMonth = useMemo(() => {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    return validOrders.reduce((sum, order) => {
      if (order.dateObject >= startOfMonth && order.dateObject <= today) {
        return sum + order.priceNumber;
      }
      return sum;
    }, 0);
  }, [validOrders]);

  const salesSamePeriodLastMonth = useMemo(() => {
    const today = new Date();
    const endOfPeriod = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    const startOfLastMonth = new Date(endOfPeriod.getFullYear(), endOfPeriod.getMonth(), 1);

    // Adjust for months with fewer days
    if (endOfPeriod.getMonth() !== (today.getMonth() + 11) % 12) {
      endOfPeriod.setDate(0); // Last day of previous month
    }

    return validOrders.reduce((sum, order) => {
      if (order.dateObject >= startOfLastMonth && order.dateObject <= endOfPeriod) {
        return sum + order.priceNumber;
      }
      return sum;
    }, 0);
  }, [validOrders]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="dashboard">
      <div className="summary-cards">
        <OrderSummary title="Orders" count={filteredOrders.length} icon="🛒" />
        <OrderSummary title="Ordered Items" count={orderedItemsCount} icon="📦" />
        <OrderSummary title="White" count={printedFramesCounts.white} icon="🖼️" />
        <OrderSummary title="Black" count={printedFramesCounts.black} icon="🖼️" />
        <OrderSummary
          title="Sales Today"
          count={todaySales}
          previousCount={yesterdaySales}
          icon="💰"
          isCurrency
          showArrow={false} // Disable arrow for Sales Today
        />
        <OrderSummary
          title="Sales Last 7 Days"
          count={salesLast7Days}
          previousCount={salesPrior7Days}
          icon="📅"
          isCurrency
        />
        <OrderSummary
          title="Sales This Month"
          count={salesThisMonth}
          previousCount={salesSamePeriodLastMonth}
          icon="📈"
          isCurrency
        />
      </div>
      <div className="filter-tabs">
        <button
          className={`filter-tab ${filter === 'All' ? 'active' : ''}`}
          onClick={() => setFilter('All')}
        >
          All ({validOrders.length})
        </button>
        <button
          className={`filter-tab ${filter === 'נקלט' ? 'active' : ''}`}
          onClick={() => setFilter('נקלט')}
        >
          נקלט ({statusCounts['נקלט'] || 0})
        </button>
        <button
          className={`filter-tab ${filter === 'הודפס' ? 'active' : ''}`}
          onClick={() => setFilter('הודפס')}
        >
          הודפס ({statusCounts['הודפס'] || 0})
        </button>
        <button
          className={`filter-tab ${filter === 'בוטל' ? 'active' : ''}`}
          onClick={() => setFilter('בוטל')}
        >
          בוטל ({statusCounts['בוטל'] || 0})
        </button>
        <button
          className={`filter-tab ${filter === 'מוכן' ? 'active' : ''}`}
          onClick={() => setFilter('מוכן')}
        >
          מוכן ({statusCounts['מוכן'] || 0})
        </button>
      </div>
      <div className="order-controls">
        <input
          type="text"
          placeholder="Filter by Order ID or Customer Name"
          className="filter-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <div className="control-buttons">
          <button className="control-btn">Filter</button>
          <button className="control-btn">Columns</button>
          <button className="control-btn">Sort</button>
          <button className="control-btn">•••</button>
        </div>
      </div>
      <OrderList orders={filteredOrders} />
    </div>
  );
};

export default Dashboard;
